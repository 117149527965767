@charset "UTF-8";
/*==========================================*/
/*============= СПЕЦИФИКАЦИЯ ==============*/
/*==========================================*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Text shadow */
/* Box shadow */
/* Border-radius */
/* Gradient */
body,
html {
  max-width: 100%;
  overflow-x: hidden; }

html {
  font-size: 15px; }

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; }

/* ScroolBar */
::-webkit-scrollbar {
  width: 11px;
  /* for vertical scrollbars */
  height: 11px;
  /* for horizontal scrollbars */ }

::-webkit-scrollbar-track {
  background: rgba(78, 53, 53, 0.1); }

::-webkit-scrollbar-thumb {
  background: rgba(2, 39, 48, 0.637); }

/* End ScroolBar */
* {
  transition: background-color 400ms ease; }

::selection {
  background: #3b778a; }

::-moz-selection {
  background: #3b778a; }

::-webkit-selection {
  background: #3b778a;
  color: #fff; }

ul,
li {
  display: block;
  padding: 0px;
  margin: 0px; }

a,
a:hover,
a:active,
a:focus,
button,
button:hover,
button:active,
button:focus,
input,
input:focus,
input:active,
textarea:focus,
textarea:active,
select:focus,
select:active {
  text-decoration: none;
  outline: none; }

a:hover {
  -moz-transition: all 0.3s ease;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease; }

.hypertext {
  font-size: 1.2rem;
  text-decoration-line: underline;
  line-height: 19px;
  color: #0067AC; }

h1,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: 700; }

h1 {
  font-size: 3.4rem;
  letter-spacing: 8px; }

h2 {
  font-family: 'Comfortaa', cursive; }

h3 {
  font-size: 1.95rem; }

.line-separator {
  width: 100%;
  border: 5px solid #EDEDED; }

.button-classic {
  color: #FCA311;
  border: 1px solid #FCA311;
  padding: 13px 25px 17px 25px; }
  .button-classic:hover {
    background: #FCA311;
    color: #fff; }

.stronger {
  font-weight: 700; }

.underline {
  text-decoration: underline; }

.line {
  width: 90%;
  border: 1px solid #6F6B6B;
  margin: 30px auto 28px auto; }

/* DOTS */
.my-dots {
  position: absolute;
  bottom: -45px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center; }

.my-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.my-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.my-dots li button:hover,
.my-dots li button:focus {
  outline: none; }

.my-dots li button:hover:before,
.my-dots li button:focus:before {
  opacity: 0.75; }

.my-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: url("../img/dots_active.svg");
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.my-dots li.slick-active button:before {
  opacity: 1;
  color: black; }

/* End DOTS */
/*==========================================*/
/*================ HEADER ==================*/
/*==========================================*/
.header {
  padding: 43px 0;
  color: #14213D; }
  .header .telephone {
    text-align: center;
    font-size: 1.46rem; }
    .header .telephone__text {
      color: #FCA311;
      display: block; }
  .header .menu {
    font-family: 'Comfortaa', cursive;
    display: flex;
    justify-content: space-between;
    font-size: 1.467rem;
    margin-top: 57px; }
    .header .menu li a {
      color: #14213D;
      display: inline-block;
      line-height: 1.4;
      text-decoration: none;
      cursor: pointer; }
    .header .menu li a:after {
      background-color: #FCA311;
      display: block;
      content: "";
      height: 2px;
      width: 0%;
      -webkit-transition: width .3s ease-in-out;
      -moz--transition: width .3s ease-in-out;
      transition: width .3s ease-in-out; }
    .header .menu li a:hover:after,
    .header .menu li a:focus:after {
      width: 100%; }
    .header .menu > li {
      position: relative; }
    .header .menu > li > a {
      color: #404040; }
      .header .menu > li > a:hover {
        color: #D5B45B; }
  .header .submenu {
    background: #EFEFEF;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 5;
    width: 200px;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: .5s ease-in-out; }
  .header .submenu a {
    color: white;
    text-align: left;
    padding: 12px 15px;
    font-size: 0.868rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .header .submenu li:last-child a {
    border-bottom: none; }
  .header .submenu li:first-child a {
    padding-top: 24px; }
  .header .menu > li:hover .submenu {
    opacity: 1;
    transform: scaleY(1); }

.offer {
  background: #83D6C4 url(../img/imagefon.png) no-repeat center center/cover;
  color: #fff;
  height: 517px;
  padding-top: 70px;
  position: relative; }
  .offer__title {
    text-align: center; }
  .offer__image {
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -225px; }

.info {
  padding: 114px 0 0 0;
  color: #14213D; }
  .info__text {
    font-size: 1.467rem;
    line-height: 40px; }

.news {
  color: #14213D; }
  .news__head {
    font-family: 'Comfortaa', cursive;
    font-size: 1.77rem;
    margin-bottom: 28px; }
  .news__item {
    margin-top: 17px; }
  .news__title {
    font-size: 1.4rem;
    line-height: 29px; }
  .news__link {
    color: #14213D !important; }
    .news__link:hover {
      text-decoration-line: underline; }
  .news__date {
    color: #534F4F;
    font-size: 1.067rem;
    line-height: 22px;
    margin-top: 7px; }
  .news__all-news {
    padding-top: 35px; }

.gallery {
  margin-top: 60px;
  background: rgba(150, 150, 150, 0.15);
  padding: 35px 0 130px 0; }
  .gallery__images {
    margin-top: 40px; }
  .gallery .card-img-top-nav {
    border: 2px solid rgba(150, 150, 150, 0.35); }

.carousel-nav {
  border: 2px solid rgba(150, 150, 150, 0.35); }

.slick-dotted.slick-slider {
  margin: 0; }

/*==========================================*/
/*================ FOOTER ==================*/
/*==========================================*/
.footer {
  padding: 37px 0 32px 0;
  font-size: 1.33rem;
  background: #3D3D3D; }
  .footer .contact-info {
    line-height: 36px; }
    .footer .contact-info__text {
      margin-top: 16px;
      color: rgba(255, 255, 255, 0.8); }
  .footer .search-form {
    display: inline;
    background: #D1D1D1;
    -webkit-border-radius: 29px;
    -moz-border-radius: 29px;
    -ms-border-radius: 29px;
    -o-border-radius: 29px;
    border-radius: 29px;
    padding: 13px 2px 14px 18px; }
    .footer .search-form .search-field {
      width: 250px;
      background: none;
      border: none; }
    .footer .search-form ::placeholder {
      font-size: 0.933rem; }
    .footer .search-form .search-button {
      -webkit-border-radius: 29px;
      -moz-border-radius: 29px;
      -ms-border-radius: 29px;
      -o-border-radius: 29px;
      border-radius: 29px;
      border: 0;
      font-size: 0.9333rem;
      padding: 17px 37px 13px 40px;
      background: #FCA311; }
    .footer .search-form input[type="text"] {
      font-size: 1rem;
      color: #14213D; }
  .footer .menu-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 105px; }
    .footer .menu-footer a {
      color: rgba(255, 255, 255, 0.8); }
      .footer .menu-footer a:hover {
        color: #FCA311; }
  .footer .links {
    display: flex;
    justify-content: center;
    margin-top: 46px; }
    .footer .links li {
      margin-right: 10px; }
  .footer .college {
    font-size: 1.9rem;
    color: rgba(255, 255, 255, 0.72); }

/*==========================================*/
/*=============== КОНТАКТЫ" ================*/
/*==========================================*/
.contacts {
  padding-top: 76px;
  background: rgba(226, 226, 226, 0.41); }
  .contacts h2 {
    font-size: 2.467rem;
    margin-bottom: 97px; }
  .contacts__contact {
    margin-top: 42px; }
    .contacts__contact_first {
      margin-top: 0; }
  .contacts__title {
    font-size: 1.9333rem; }
  .contacts__description {
    font-size: 1.533rem; }
  .contacts #contact-with-us {
    margin-bottom: 52px; }
  .contacts .form__field {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 20px;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    -ms-border-radius: 19px;
    -o-border-radius: 19px;
    border-radius: 19px;
    border: 1px solid #14213D;
    padding: 11px 0 13px 25px; }
  .contacts .form__button {
    margin-bottom: 63px;
    width: 100%;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    -ms-border-radius: 19px;
    -o-border-radius: 19px;
    border-radius: 19px;
    border: none;
    background: #FCA311;
    padding: 10px 12px; }
  .contacts .form .text-area {
    max-height: 430px;
    min-height: 125px; }
  .contacts .form .error {
    border: 1px solid #e34a39;
    font-size: 0.9rem;
    padding-left: 18px; }
  .contacts .form label.error {
    border: none; }

/*==========================================*/
/*======= АВТОРИЗАЦИЯ В АДМИН-ПАНЕЛЬ =======*/
/*==========================================*/
.auth {
  font-size: 10px;
  padding-top: 60px; }
  .auth__form {
    background: rgba(174, 174, 174, 0.18);
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    border-radius: 14px;
    margin-top: 55px;
    padding: 66px 0;
    text-align: center; }
  .auth__title {
    font-size: 2.67em;
    margin-bottom: 55px; }
  .auth label {
    display: block;
    font-size: 1.6em; }
  .auth input {
    width: 60%;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    border-radius: 28px;
    background: #FFFFFF;
    padding: 10px 10px 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.33);
    display: block;
    margin: 0 auto 36px auto; }
  .auth input[type='password'] {
    font-size: 1.05rem; }
  .auth input[type="text"] {
    font-size: 1.6em; }
  .auth button {
    background: #FCA311;
    -webkit-border-radius: 64px;
    -moz-border-radius: 64px;
    -ms-border-radius: 64px;
    -o-border-radius: 64px;
    border-radius: 64px;
    padding: 15px 60px 15px 60px;
    border: none;
    font-size: 1.6em; }
  .auth .flash-login {
    font-size: 1.3rem; }
    .auth .flash-login p {
      margin: 0; }

/*==========================================*/
/*=========== РАСПИСАНИЕ ЗАНЯТИЙ ===========*/
/*==========================================*/
.schedule {
  padding: 117px 0 73px 0; }
  .schedule__title {
    margin-bottom: 79px; }
  .schedule .calls__title {
    font-size: 1.67rem;
    margin-bottom: 10px; }
    .schedule .calls__title_dinner {
      margin-bottom: 30px; }
  .schedule .calls__text {
    font-size: 1.333rem; }
    .schedule .calls__text_first {
      margin-top: 40px; }

.main_schedule {
  padding-bottom: 149px; }
  .main_schedule .yellow-color {
    color: #FCA311; }
  .main_schedule__description {
    color: #14213D;
    line-height: 20px;
    text-align: center;
    margin-top: 15px; }
  .main_schedule .coure_buttons {
    display: flex;
    justify-content: space-around; }
    .main_schedule .coure_buttons li {
      margin: 42px 0 90px 0; }
  .main_schedule .exams {
    margin: 31px 0 93px 0; }
  .main_schedule .form-schedule {
    margin-top: 33px; }
  .main_schedule .input_email {
    -webkit-border-radius: 29px;
    -moz-border-radius: 29px;
    -ms-border-radius: 29px;
    -o-border-radius: 29px;
    border-radius: 29px;
    border: 1px solid #FCA311;
    padding: 13px 0 14px 19px;
    width: 236px; }
  .main_schedule select {
    -webkit-border-radius: 29px;
    -moz-border-radius: 29px;
    -ms-border-radius: 29px;
    -o-border-radius: 29px;
    border-radius: 29px;
    border: 1px solid #FCA311;
    background: #fff;
    padding: 13px 15px 14px 17px;
    width: 127px;
    color: #14213D; }
  .main_schedule ::placeholder {
    color: rgba(108, 117, 125, 0.7);
    font-size: 1rem; }
  .main_schedule .email_btn {
    margin: 28px auto 0 auto;
    width: 165px;
    display: block;
    background: none;
    -webkit-border-radius: 29px;
    -moz-border-radius: 29px;
    -ms-border-radius: 29px;
    -o-border-radius: 29px;
    border-radius: 29px; }
    .main_schedule .email_btn:hover {
      background: #FCA311; }
  .main_schedule .flash {
    margin-top: 25px; }
    .main_schedule .flash p {
      margin: 0; }

/*==========================================*/
/*================ НОВОСТИ =================*/
/*==========================================*/
.news-page {
  background: rgba(240, 240, 240, 0.38);
  padding: 42px 0 100px 0; }
  .news-page__title {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.4rem; }
  .news-page__pubdate {
    font-size: 1rem;
    color: #14213D; }
  .news-page__text {
    font-size: 1.4rem;
    text-align: justify;
    margin-top: 36px;
    line-height: 45px; }
    .news-page__text:first-letter {
      font-size: 2.4rem; }
  .news-page .gallery-news {
    background: none !important;
    margin-top: 85px;
    padding: 0; }

/*============== HAMBURGER MENU ===============*/
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.hamRotate.active {
  transform: rotate(45deg); }

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #14213D;
  stroke-width: 5.5;
  stroke-linecap: round; }

.ham .top {
  stroke-dasharray: 40 121; }

.ham .bottom {
  stroke-dasharray: 40 121; }

.ham.active .top {
  stroke-dashoffset: -68px; }

.ham.active .bottom {
  stroke-dashoffset: -68px; }

/*============== END HAMBURGER MENU ===============*/
/*==========================================*/
/*============== ВСЕ НОВОСТИ ===============*/
/*==========================================*/
.all_news {
  padding: 69px 0;
  color: #14213D;
  background: rgba(240, 240, 240, 0.48); }
  .all_news__title {
    font-size: 2.8rem;
    margin-bottom: 50px; }
  .all_news__item {
    margin-top: 50px; }
  .all_news__heading {
    font-size: 1.8rem; }
    .all_news__heading a {
      color: #14213D; }
      .all_news__heading a:hover {
        border-bottom: 2px solid #14213D; }
  .all_news__pubdate {
    font-size: 1.067rem; }
  .all_news__separator {
    margin-top: 30px;
    border: 1px solid #D1D1D1; }

.paginator {
  margin-top: 20px; }
  .paginator__link {
    font-size: 1.1rem;
    padding: 4px 10px 8px 10px;
    margin-right: 15px; }

.main_info {
  padding: 47px 0 77px 0;
  background: rgba(231, 231, 231, 0.29);
  color: #14213D; }
  .main_info a {
    color: #14213D; }
  .main_info__title {
    font-size: 2.46rem; }
  .main_info__name {
    font-size: 1.73rem;
    margin-top: 89px; }
  .main_info__description {
    margin-top: 89px; }
  .main_info__item {
    font-size: 1.267rem;
    margin-top: 35px; }
  .main_info__founder {
    font-size: 1.53rem;
    font-weight: 700;
    margin-top: 42px; }
  .main_info .separator {
    border: 1px solid #D1D1D1;
    width: 90%;
    margin: 79px auto; }
  .main_info #leader {
    font-size: 1.4rem; }

/*==========================================*/
/*=========== УЧЕБНЫЕ МАТЕРИАЛЫ=============*/
/*==========================================*/
.educ_materials {
  padding: 123px 0 259px 0;
  background: rgba(218, 218, 218, 0.23); }
  .educ_materials__title {
    font-size: 2.46rem; }
  .educ_materials__instruction {
    margin-top: 89px;
    font-size: 1.6rem; }
  .educ_materials__steps {
    margin-top: 23px;
    font-size: 1.4rem; }
    .educ_materials__steps li {
      margin-top: 10px; }
  .educ_materials #exams {
    margin-top: 60px; }
  .educ_materials__link {
    margin-top: 23px;
    font-size: 1.4rem; }

/*==========================================*/
/*============== РУКОВОДСТВО ===============*/
/*==========================================*/
.leaders {
  padding: 62px 0 150px 0;
  background: #F7F7F7; }
  .leaders a {
    color: #3D3D3D; }
  .leaders h2 {
    font-size: 2.467rem; }
  .leaders h3 {
    font-family: 'Comfortaa', cursive;
    font-size: 2.133rem; }
  .leaders__title {
    margin-bottom: 99px; }
  .leaders__title_first {
    margin: 132px 0 99px 0; }
  .leaders__title_default {
    margin-bottom: 99px; }
  .leaders__title_departament {
    margin-top: 125px; }
  .leaders__address, .leaders__phone, .leaders__email {
    font-size: 1.267rem;
    margin-top: 22px; }
  .leaders__name {
    font-size: 1.8rem;
    font-weight: bold;
    color: #3D3D3D; }
  .leaders .line {
    margin: 70px auto 60px auto; }

/*==========================================*/
/*================ VACANT ==================*/
/*==========================================*/
.vacant {
  background: rgba(231, 231, 231, 0.36);
  padding: 97px 0 200px 0;
  color: #14213D; }
  .vacant ul,
  .vacant li {
    display: list-item; }
  .vacant__table {
    margin: 0 auto;
    text-align: center;
    font-size: 1.2rem; }
  .vacant__pubdate {
    font-size: 1.6rem;
    margin-bottom: 40px; }
  .vacant #table__title {
    text-align: center;
    font-size: 1.4rem !important;
    font-weight: 700; }
    .vacant #table__title td {
      padding: 12px 0px; }
  .vacant .table__speciality {
    text-align: left;
    font-size: 1.28rem;
    width: 605px;
    padding-left: 5px; }
  .vacant .table__info {
    padding: 4px 0; }
  .vacant .table__course {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .vacant__description {
    margin-top: 45px;
    font-size: 1.4rem; }
  .vacant__conditions {
    font-size: 1.3rem;
    padding: 24px 0 24px 10px; }
  .vacant__additional {
    margin: 20px 0; }
  .vacant__more_info {
    margin-top: 25px;
    font-weight: 700;
    font-size: 1.25rem; }

/*==========================================*/
/*=========== Спасибо за заявку ============*/
/*==========================================*/
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.56);
  z-index: 100;
  display: none; }

.modal-consult {
  background: #fca311;
  width: 480px;
  min-height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 40px;
  box-shadow: 0 0 0 6px rgba(153, 153, 153, 0.3);
  border-radius: 10px;
  color: #fff; }
  .modal-consult .overlay__close {
    position: absolute;
    top: 0;
    right: -40px;
    line-height: 1.8rem;
    font-size: 2.8rem;
    color: #fca311;
    cursor: pointer; }
  .modal-consult .overlay__subtitle {
    font-size: 1.5rem;
    font-weight: 900; }
  .modal-consult .overlay__description {
    margin-bottom: 15px; }

/*==========================================*/
/*=============== ДОКУМЕНТЫ ================*/
/*==========================================*/
.documents {
  padding: 91px 0; }
  .documents__title {
    font-size: 2.467rem; }
  .documents__subtitle {
    font-size: 1.8rem;
    margin: 91px 0 51px 0; }
  .documents #document__titles {
    font-size: 1.2rem;
    font-weight: 700;
    background: #f4f4f4 !important; }
    .documents #document__titles td {
      padding: 5px 5px; }
  .documents__document {
    padding: 3px 7px; }
  .documents tr {
    background: #F1F5FA; }
  .documents tr:nth-child(odd) {
    background: #E4ECF5; }

/*==========================================*/
/*========= ВОПРОСЫ ПО ПОСТУПЛЕНИЮ =========*/
/*==========================================*/
.questions {
  background: rgba(228, 228, 228, 0.33);
  padding: 125px 0; }
  .questions__title {
    font-size: 2.467rem;
    margin-bottom: 80px; }
  .questions__questions {
    color: #14213D; }
    .questions__questions li {
      display: list-item;
      font-size: 1.267rem;
      font-weight: bold; }
    .questions__questions li:nth-child(even) {
      display: block;
      font-size: 1.133rem;
      font-weight: 400;
      margin: 18px 0 61px 0; }

/*==========================================*/
/*======= ДОКУМЕНТЫ ДЛЯ ПОСТУПЛЕНИЯ ========*/
/*==========================================*/
.doc-entrance {
  padding: 125px 0;
  background: rgba(228, 228, 228, 0.33); }
  .doc-entrance__last-ol {
    margin-bottom: 20px !important; }
  .doc-entrance ol {
    margin-bottom: 81px; }
  .doc-entrance li {
    display: list-item;
    font-size: 1.2rem;
    line-height: 38px; }
  .doc-entrance h3 {
    font-size: 1.9rem;
    margin-bottom: 44px;
    text-align: center; }
  .doc-entrance__title {
    font-size: 2.467rem;
    margin-bottom: 80px; }
  .doc-entrance__subtitle {
    font-size: 1.7rem !important;
    margin-bottom: 44px;
    text-align: center; }
  .doc-entrance .line {
    border: 1px solid #6F6B6B;
    width: 100%;
    margin-bottom: 40px; }
  .doc-entrance .document-process-data {
    font-size: 1.4rem;
    color: #FCA311; }
  .doc-entrance .btn-download-document {
    margin-top: 13px;
    cursor: pointer; }
  .doc-entrance__contacts {
    margin-top: 85px; }
  .doc-entrance__item-contacts {
    font-size: 1.15rem;
    margin-bottom: 11px; }

.information {
  margin-top: 85px; }
  .information__item-contacts {
    font-size: 1.15rem;
    margin-bottom: 11px; }

/*==========================================*/
/*========== ДНИ ОТКРЫТЫХ ДВЕРЕЙ ===========*/
/*==========================================*/
.open-days {
  background: rgba(224, 224, 224, 0.34);
  padding: 142px 0; }
  .open-days__about {
    font-size: 1.7rem;
    margin: 38px 0; }
  .open-days__start {
    font-size: 1.66rem;
    color: #FCA311;
    font-weight: bold;
    margin-bottom: 100px; }
  .open-days__subtitle {
    font-size: 1.4rem;
    font-weight: bold; }
  .open-days__date {
    margin-top: 15px; }
    .open-days__date li {
      font-size: 1.2rem;
      margin-bottom: 27px; }

/*==========================================*/
/*========== СТОИМОСТЬ ОБУЧЕНИЯ ============*/
/*==========================================*/
.cost {
  background: rgba(224, 224, 224, 0.34);
  padding: 100px 0 142px 0; }
  .cost__title {
    margin-bottom: 45px; }
  .cost__titles {
    font-size: 1.18rem;
    font-weight: bold;
    background: #FCA311; }
    .cost__titles td {
      padding: 9px 0; }
  .cost__text {
    font-size: 1.1rem; }
    .cost__text td {
      padding: 6px 0; }
  .cost__description {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 30px; }

/*==========================================*/
/*============= СПЕЦИАЛЬНОСТИ ==============*/
/*==========================================*/
.all-specialties {
  background: rgba(224, 224, 224, 0.34);
  padding: 100px 0 142px 0; }
  .all-specialties__title {
    margin-bottom: 55px; }
    .all-specialties__title_second {
      font-size: 1.8rem;
      margin-top: 55px; }
  .all-specialties__titles {
    font-size: 1.18rem;
    font-weight: bold;
    background: #FCA311; }
    .all-specialties__titles_classes {
      font-size: 1rem; }
      .all-specialties__titles_classes td {
        padding: 3px 0; }
  .all-specialties .period-study {
    padding: 6px 0; }
  .all-specialties__item {
    font-size: 1.1rem; }
    .all-specialties__item td {
      padding: 3px 0; }
  .all-specialties__more {
    font-size: 1.15rem; }
    .all-specialties__more li {
      margin-bottom: 16px; }
  .all-specialties__stars {
    font-size: .9rem; }

/*==========================================*/
/*============== ПЛАН ПРИЕМА ===============*/
/*==========================================*/
.plan {
  background: rgba(224, 224, 224, 0.34);
  padding: 100px 0 142px 0; }
  .plan__title {
    font-size: 2.4rem;
    margin-bottom: 80px; }
  .plan__table {
    margin-top: 65px; }
  .plan__titles {
    font-size: 1.18rem;
    font-weight: bold;
    background: #c36d6d; }
  .plan__item {
    font-size: 1.1rem; }
    .plan__item td {
      padding: 5px 6px; }

/*==========================================*/
/*========== СТАТИСТИКА НАБОРА =============*/
/*==========================================*/
.statistics {
  background: rgba(224, 224, 224, 0.34);
  padding: 100px 0 142px 0; }
  .statistics__title {
    font-size: 2.4rem;
    margin-bottom: 75px; }
  .statistics #statistics_titles {
    font-size: 1rem; }
    .statistics #statistics_titles td {
      padding: 0 4px;
      background: rgba(252, 163, 17, 0.5); }
  .statistics__row td {
    padding: 3px 0; }

/*==========================================*/
/*================ COOKIE ==================*/
/*==========================================*/
.cookie {
  padding: 90px 0 70px 0;
  font-size: 1.2rem;
  color: #505659; }
  .cookie__title {
    font-size: 2.4rem;
    color: #14213D !important; }
  .cookie__subtitle {
    font-size: 1.99rem; }

.cookieinfo {
  padding: 16px 18px !important; }

/*==========================================*/
/*============= HAMBURGER MENU =============*/
/*==========================================*/
.hamburger {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #fff;
  overflow-y: hidden;
  transition: 0.7s; }

.overlay-content {
  position: relative;
  top: 3%;
  width: 100%;
  text-align: center;
  margin-top: 15px; }
  .overlay-content li {
    margin: 8px 0; }
    .overlay-content li a {
      color: #14213D; }
      .overlay-content li a:hover {
        text-decoration: underline; }

.hamburger a:hover, .hamburger a:focus {
  color: #f1f1f1; }

.hamburger .closebtn {
  position: absolute;
  text-decoration: none;
  top: 0;
  color: #14213D;
  transition: 0.3s;
  right: 20px;
  font-size: 4rem;
  z-index: 1000; }
